<template lang="pug">
.main-container
  .reports__wrapper
    router-link.reports__item(:to="{ name: 'reports-detail', query: {reportName: 'Activity'} }")
      .reports__item-top
        .reports__item-icon.reports__item-icon--activity
      .reports__item-bottom Отчет по видам деятельности
    router-link.reports__item(:to="{ name: 'reports-detail', query: {reportName: 'Inspectors'} }")
      .reports__item-top
        .reports__item-icon.reports__item-icon--inspectors
      .reports__item-bottom Отчет по инспекторам
        // Deprecated
    //router-link.reports__item(:to="{ name: 'reports-detail', query: {reportName: 'Regime'} }")
    //  .reports__item-top
    //    .reports__item-icon.reports__item-icon--regime
    //  .reports__item-bottom Общий отчет по налоговому режиму
    router-link.reports__item(:to="{ name: 'reports-detail', query: {reportName: 'RegimeUgns'} }")
      .reports__item-top
        .reports__item-icon.reports__item-icon--regime-ugns
      .reports__item-bottom Отчет по налоговому режиму
    router-link.reports__item(:to="{ name: 'reports-detail', query: {reportName: 'Kkm'} }")
      .reports__item-top
        .reports__item-icon.reports__item-icon--kkm
      .reports__item-bottom Отчет по ККМ
    router-link.reports__item(:to="{ name: 'reports-detail', query: {reportName: 'Territory'} }")
      .reports__item-top
        .reports__item-icon.reports__item-icon--territory
      .reports__item-bottom Территориальный отчет <br>по УГНС
    router-link.reports__item(:to="{ name: 'reports-detail', query: {reportName: 'BusinessType'} }")
      .reports__item-top
        .reports__item-icon.reports__item-icon--regime
      .reports__item-bottom Отчет по виду объекта
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({

});
</script>
